import React from 'react';
import './WomenKalyan.css';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';

function WomenKalyan() {
    return (
        <div className="container">
              <div className="social-media-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="icon facebook">
                <FaFacebook />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="icon linkedin">
                <FaLinkedin />
            </a>
            <a href="https://www.instagram.com/womenkalyan/profilecard/?igsh=MW5udzUxczc4cHg3cQ==" target="_blank" rel="noopener noreferrer" className="icon instagram">
                <FaInstagram />
            </a>
        </div>
            <h1 className="page-title">Women Kalyan Mitra Foundation</h1>
            
            <p className="description">
                WOMENKALYAAN MITRA FOUNDATION is a section 8 company incorporated under the Companies Act 2013. The primary objective is to render free legal and other assistance to needy women, especially focusing on the upliftment of downtrodden women.
            </p>
            <h2 className="section-title">Initiatives, Articles, and Useful Resources</h2>

            {/* Combined Table */}
            <section className="table-section">
                <div className="table-container">
                    <table className="responsive-table">
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Category</th>
                                <th>Subject</th>
                                <th>Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Initiatives Section */}
                            <tr>
                                <td colSpan="4" className="section-heading">Initiatives</td>
                            </tr>
                            {/* Adding all 12 initiatives */}
                            <tr>
                                <td>1</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation to JPC on Amendment of Waqf Board Act to include support for Muslim women</td>
                                <td><a href="https://drive.google.com/file/d/1bxhFXSvzJm1iwhURVmXL_NQGNT28nQJX/view?usp=drivesdk" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation to Chief of the Indian Air Force requesting to convene court martial proceeding against a Wing Commander accused of raping a Flying Officer</td>
                                <td><a href="https://drive.google.com/file/d/1c-fynDihmClw5C8k3wHKwiw50z5Qw7Fa/view?usp=drivesdk" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">PROTECTION OF WOMEN FROM DOMESTIC VIOLENCE ACT</td>
                                <td><a href="https://docs.google.com/document/d/17KKnnYv__deMph4F9HIft_shLcz5iMg-/edit?usp=drive_link&ouid=111364126960359822747&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation to bring comprehensive reforms in the THE PROTECTION OF WOMEN FROM DOMESTIC VIOLENCE ACT, 2005 to make it in line with the global best practices for the benefit of Indian Women.</td>
                                <td><a href="https://docs.google.com/document/d/1NR_-xFrAW3RRYfUvPVh5_CR3WsnpJ5-p/edit?usp=drive_link&ouid=111364126960359822747&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation to provide to children below poverty line Central grants in subscriptions of NPS Vatsalya Scheme to needy women</td>
                                <td><a href="https://docs.google.com/document/d/1I1oIIReigrhAaizYjKmyuK2Mi_p88Pcm/edit?usp=drive_link&ouid=111364126960359822747&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Suggestions for creation of a national App for integrated helpline for women in distress in all Indian languages </td>
                                <td><a href="https://docs.google.com/document/d/17tEJ5n-gra5ogZGAgVx53yGf2WTEBsxo/edit?usp=drive_link&ouid=111364126960359822747&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation to provide 90% grants to needy women entrepreneurs/sellers to Open Network for Digital Commerce (ONDC)</td>
                                <td><button className="action-button">Under Construction</button></td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation to request grants for organizing a virtual national workshop on reforms in laws relating to crime against women</td>
                                <td><button className="action-button">Under Construction</button></td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation to increase the posts for women police/paramilitary personnel in the states to at least 33% of the total police/paramilitary force</td>
                                <td><button className="action-button">Under Construction</button></td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation for approval of a Central scheme to set up Government/NGO residential shelter home capacities for destitute/poor women</td>
                                <td><button className="action-button">Under Construction</button></td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation for approval of a central scheme to distribute women-specific designed smartphones to poor and needy women</td>
                                <td><button className="action-button">Under Construction</button></td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Initiatives</td>
                                <td className="ellipsis">Representation for approval of at least 10% CSR on women-centric activities</td>
                                <td><button className="action-button">Under Construction</button></td>
                            </tr>
                            
                            {/* Articles Section */}
                            <tr>
                                <td colSpan="4" className="section-heading">Articles on Family Law</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>Articles</td>
                                <td className="ellipsis">Family Matters</td>
                                <td><button className="action-button">Under Construction</button></td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>Articles</td>
                                <td className="ellipsis">Guide for Women on Domestic Violence</td>
                                <td><button className="action-button">Under Construction</button></td>
                            </tr>

                            {/* Resources Section */}
                            <tr>
                                <td colSpan="4" className="section-heading">Useful Resources</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>Resources</td>
                                <td className="ellipsis">Ministry of Women and Child Development, Government of India</td>
                                <td><a href="https://wcd.nic.in/" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>Resources</td>
                                <td className="ellipsis">Department of Women and Child Development, Government of NCT Delhi</td>
                                <td><a href="https://wcd.delhi.gov.in/" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>Resources</td>
                                <td className="ellipsis">Delhi Pension Scheme to Women in Distress, Date: 18-10-2022</td>
                                <td><a href="https://wcd.delhi.gov.in/sites/default/files/WCD/circulars-orders/delhipensionwomendistressnotification_1.pdf" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>Resources</td>
                                <td className="ellipsis">Grants to Voluntary organization for women development by Government of NCT Delhi</td>
                                <td><a href="https://wcd.delhi.gov.in/wcd/delhi-grants-rules-vac-gia" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>Resources</td>
                                <td className="ellipsis">Mission Shakti of Central Govt for Women Empowerment</td>
                                <td><a href="https://missionshakti.wcd.gov.in/about" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>Resources</td>
                                <td className="ellipsis">National Commission for Women</td>
                                <td><a href="http://ncw.nic.in/" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                            <tr>
                                <td>21</td>
                                <td>Resources</td>
                                <td className="ellipsis">Naz Foundation</td>
                                <td><a href="https://www.nazindia.org/" target="_blank" rel="noopener noreferrer" className="resource-link">Visit Link</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
}

export default WomenKalyan;

